import CookieConsent from "./utils/cookies/cookies.js";

const cookieConsent = new CookieConsent({
    title: "This website uses cookies",
    message:
        "We use essential cookies to ensure the basic functionality of our website and to monitor performance and errors. These cookies do not collect any personal information and are necessary for the website to work properly." +
        "<br><br>" +
        "We also offer the option to use analytics cookies.</br>",
    delay: 1000,
    expires: 90,
    onAccept: function () {
        cookieCheck();
    },
    uncheckBoxes: true,
    acceptBtnLabel: "Accept",
    moreInfoLabel: "Learn more",
    advancedBtnLabel: "Customise",
    cookieTypesTitle: "Select which cookies you want to accept",
    fixedCookieTypeLabel: "Functional",
    fixedCookieTypeDesc:
        "These help us remember your preferences, keep you logged in, and maintain key features of the site. They also inform us about errors and performance issues.",
    cookieTypes: [
        {
            type: "Analytics",
            value: "analytics",
            description: "We use analytics cookies to understand how visitors interact with our website. This helps us improve our site's functionality, content, and overall user experience. We use two analytics tools:\n" +
                "\n" +
                "Google Analytics 4: This service helps us analyze website traffic patterns, understand which pages are most popular, and see how visitors navigate our site. It provides aggregate data about user behavior without identifying individual users.\n" +
                "\n" +
                "Microsoft Clarity: This tool helps us visualize how users interact with our pages through heatmaps and session recordings. It allows us to identify usability issues and optimize our website design. All personal information is automatically redacted from the recordings.\n" +
                "\n" +
                "These analytics cookies are completely optional. They do not contain or collect any personally identifiable information, and the data is processed in a way that does not identify individual users. However, we only activate these cookies with your explicit consent.",
        },
    ],
});

export function cookieCheck() {
    if (cookieConsent.preference("analytics") === true) {
        try {
            gtag("consent", "update", {
                "analytics_storage": "granted"
            });
            window.clarity("consent");
        } catch (e) {
            // Have an ad blocker
            console.warn(e);
        }
    }
}
