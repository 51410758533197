import "corejs-typeahead";
import FuzzySearch from "fz-search";
import { onLoad } from "./utils/util";

export function initSuggestions(url, inputSelector = "#keyword") {

    var fuzzyhound = new FuzzySearch({ output_limit: 100, output_map: "alias", token_field_min_length: 2 });

    $.ajax({
        url: url,
        method: "GET",
        success: function (data) {
            fuzzyhound.setOptions({
                source: data,
                keys: ["name", "type"]
            });
        }
    });

    $(inputSelector).typeahead(
        {
            hint: true,
            highlight: false,
            minLength: 3
        },
        {
            name: "Suggestions",
            source: fuzzyhound,
            display: "name",
            limit: 100,
            templates: {
                suggestion: function (item) {
                    return "<span class=\"lead\" title=\"" + item.name + "\">" + fuzzyhound.highlight(item.name) + "<span class=\"text-gray-600 font-small ml-3\">in " + item.type + "</span></span>";
                }
            }
        }
    ).on("typeahead:select", function (ev, suggestion) {
        $(this).typeahead("val", suggestion.name);
    });
}

export function initDirectorySuggestions (directoryForm = "#directory-form"){
    if (document.querySelector(directoryForm)) {
        const url = document.querySelector(directoryForm).getAttribute("data-suggestions");
        initSuggestions(url);
    }
}

onLoad(()=>{
    initDirectorySuggestions();
});