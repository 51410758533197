
export const onLoad = (callback) => {
    document.addEventListener("DOMContentLoaded", callback);
};

export const Breakpoints = {
    sm: 540,
    md: 720,
    lg: 960,
    xl: 1140
};


export const DateFormatsForDateFns = {
    ddmmyyyy: "dd/MM/yyyy",
    dateWithHourMins: "dd/MM/yyyy HH:mm"
};

/*
 * 
 * @param {Element} btn 
 * @param {string} state 
 */
export function toggleSubmitButtonState(btn, state) {
    if (state === "running") {
        btn.classList.add("running");
        btn.setAttribute("disabled", true);
    } else {
        btn.classList.remove("running");
        btn.removeAttribute("disabled");
    }
}