import Swal from "sweetalert2";
import "jquery-validation";

const subscribeSubmission = (form, handler, validator) => {

    var $btn = $(handler.submitButton);
    $btn.addClass("running").attr("disabled", true);
    var url = $(form).attr("data-action");

    $.ajax({
        url: url,
        method: "GET",
        data: { email: $(form).find('input[name="email"]').val() },
        success: function (response) {
            if (response) {
                Swal.fire({
                    title: "Success!",
                    text: "Thank you for subscribing to Dsposal news and articles",
                    icon: "success",
                    confirmButtonText: "Close"
                });
                $btn.removeClass("running").attr("disabled", false);
                $(form).find('input[name="email"]').val(null).trigger("change");
                validator.resetForm();
            }
            else {
                Swal.fire({
                    title: "Ooops!",
                    text: "Something went wrong",
                    icon: "error",
                    confirmButtonText: "Close"
                });
                $btn.removeClass("running").attr("disabled", false);

            }
        },
        error: function (/*error*/) {
            Swal.fire({
                title: "Ooops!",
                text: "Something went wrong",
                icon: "error",
                confirmButtonText: "Close"
            });
            $btn.removeClass("running").attr("disabled", false);
        }
    });
};

export const subscribeFormValidation = () => {

    $('[data-subscribe-form="mailchimp"]').each(function(index, form) {
        const validator = $(form).validate({
            messages: {
                Email: {
                    required: "Please enter your email, you knob"
                }
            },
            errorClass: "is-invalid",
            validClass: "is-valid",
            errorPlacement: function (error, element) {
                $(error).addClass("invalid-feedback text-white border-light rounded bg-danger p-1");
                error.insertAfter(element);
            },
            submitHandler: function (form, e) {
                e.preventDefault();

                subscribeSubmission(form, this, validator);
                return false;
            }
        });
    });
};
