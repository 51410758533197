/**
 * 
 * @param {HTMLElement} element 
 */
export function initYoutubeEmbed(element) {
    element.addEventListener("click", function (e) {
        e.preventDefault();

        // Switch out the image with the iframe!
        const videoId = element.dataset.id;

        const embed = `https://www.youtube.com/embed/${videoId}?&autoplay=1`;

        const iframe = document.createElement("iframe");
        iframe.src = embed;

        if (element.dataset.title) iframe.title = element.dataset.title;
        if (element.dataset.frameborder) iframe.setAttribute("frameborder", element.dataset.title);
        if (element.dataset.allow) iframe.setAttribute("allow", element.dataset.allow);
        if (element.dataset.allowfullscreen) iframe.setAttribute("allowfullscreen", "");

        element.innerHTML = ""; // Remove the image and span!

        element.append(iframe);
    });
}