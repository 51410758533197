import { Breakpoints } from "./utils/util";


function navbarInit($nav, scrollPosition, addClass) {
    //Need to check for negative scrollPosition for Safari
    if (scrollPosition <= 0)
        $nav.removeClass("headroom--pinned headroom--not-top").addClass(addClass && "bg-soft");
    else
        $nav.addClass("headroom--pinned headroom--not-top").removeClass(addClass && "bg-soft");
}

function navBarCollapse($navbarCollapse) {
    // Collapse navigation
    $navbarCollapse.on("hide.bs.collapse", function () {
        var $this = $(this);
        $this.addClass("collapsing-out");
        $("html, body").css("overflow", "initial");
    });

    $navbarCollapse.on("hidden.bs.collapse", function () {
        var $this = $(this);
        $this.removeClass("collapsing-out");
    });

    $navbarCollapse.on("shown.bs.collapse", function () {
        $("html, body").css("overflow", "hidden");
    });

    $(".navbar-main .dropdown").on("hide.bs.dropdown", function () {
        var $this = $(this).find(".dropdown-menu");

        $this.addClass("close");

        setTimeout(function () {
            $this.removeClass("close");
        }, 200);

    });

    $(".dropdown-menu a.dropdown-toggle").on("click", function (/*e*/) {
        if (!$(this).next().hasClass("show")) {
            $(this).parents(".dropdown-menu").first().find(".show").removeClass("show");
        }
        var $subMenu = $(this).next(".dropdown-menu");
        $subMenu.toggleClass("show");

        $(this).parents("li.nav-item.dropdown.show").on("hidden.bs.dropdown", function (/*e*/) {
            $(".dropdown-submenu .show").removeClass("show");
        });

        return false;
    });

    if (window.innerWidth >= Breakpoints.lg) {
        $(".nav-item.dropdown").hover(function () {
            $(this).find("> .dropdown-toggle").dropdown("toggle");
        },
        function () {
            $(this).removeClass("show");
            $(this).find(".dropdown-menu").removeClass("show");
            $(this).find("> .dropdown-toggle").attr("aria-expanded", "false");
        });

        $(".dropdown-menu a.dropdown-toggle").hover(function () {
            if (!$(this).next().hasClass("show")) {
                $(this).parents(".dropdown-menu").first().find(".show").removeClass("show");
            }
            var $subMenu = $(this).next(".dropdown-menu");
            $subMenu.toggleClass("show");

            $(this).parents("li.nav-item.dropdown.show").on("hidden.bs.dropdown", function (/*e*/) {
                $(".dropdown-submenu .show").removeClass("show");
            });

            return false;
        },
        function () {
            $(this).removeClass("show");
            $(this).find(".dropdown-menu").removeClass("show");
            $(this).attr("aria-expanded", false);
        });
    }
}


export function initNav(addClass = true){
    navBarCollapse($(".navbar-main .collapse"));

    if ($("#navbar-main").length) {
    
        // Allow for the reload browser 'jump' to happen before evaluating the scroll position
        setTimeout(function () {
            navbarInit($("#navbar-main"), window.pageYOffset || document.documentElement.scrollTop, addClass);
        }, 50);
    
        // Scroll event
        $(window).bind("scroll", function (/*e*/) {
            navbarInit($("#navbar-main"), window.pageYOffset || document.documentElement.scrollTop, addClass);
        });
    }
}